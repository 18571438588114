@import "../../styles/helpers";

.top {
    margin-bottom: 34px;
    @include m {
        margin-bottom: 32px;
        padding-bottom: 16px;
        border-bottom: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } } }

.section {
    padding-top: 15px; }

.title {
    margin-bottom: 16px; }

.info {
    color: $neutrals4;
    strong {
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; } } }
.error {
    display: flex;
    align-items: center;
    margin-top: 30px;
    padding: 14px;
    border-radius: 8px;
    background: rgba($pink, .08);
    color: $pink;
    @include m {
        align-items: stretch; }
    .preview {
        flex-shrink: 0;
        width: 32px;
        margin-right: 16px;
        svg {
            fill: $pink; } } }

.row {
    display: flex;
    margin: 0 -16px;
    @include d {
        display: block;
        margin: 0; } }

.col {
    flex: 0 0 calc(80% - 32px);
    width: calc(80% - 32px);
    margin: 0 16px;
    @include d {
        width: 100%;
        margin: 0; }
    &:not(:last-child) {
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 32px;
            padding-bottom: 32px;
            border-bottom: 1px solid $neutrals6;
            @include dark {
                border-color: $neutrals3; } } } }

.user {
    display: flex;
    @include d {
        max-width: 416px; } }

.avatar {
    flex-shrink: 0;
    width: 128px;
    height: 128px;
    margin-right: 32px;
    @include m {
        width: 64px;
        height: 64px;
        margin-right: 16px; }
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.details {
    flex-grow: 1; }

.stage {
    margin-bottom: 8px;
    @include body-bold-2; }

.text {
    margin-bottom: 16px;
    @include caption-2;
    color: $neutrals4; }

.file {
    display: inline-block;
    position: relative;
    overflow: hidden; }

.load {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 140px;
    opacity: 0; }

.list {
    margin-bottom: 20px;
    @include d {
        display: flex;
        margin: 0 -20px 64px; }
    @include m {
        display: block;
        margin: 0 0 32px; } }

.item {
    @include d {
        flex: 0 0 calc(50% - 40px);
        width: calc(50% - 40px);
        margin: 0 20px; }
    @include m {
        width: 100%;
        margin: 0; }
    & > .button {
        margin-top: 32px; }
    &:not(:last-child) {
        margin-bottom: 20px;
        @include d {
            margin-bottom: 0; }
        @include m {
            margin-bottom: 32px; } } }

.category {
    margin-bottom: 32px;
    @include body-bold-2; }

.fieldset {
    display: flex;
    @include m {
        margin-top: 30px;
        display: block; }
    & > .field {
        flex: 50%;
        margin-right: 20px;
        &:not(:last-child) {
            margin-bottom: 32px; } } }

.box {
    position: relative;
    .button {
        position: absolute;
        right: 12px;
        bottom: 8px;
        height: 32px;
        padding: 0 10px; } }

.note {
    color: $neutrals4; }

.btns {
    display: flex;
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $neutrals6;
    @include m {
        display: block;
        text-align: center; }
    @include dark {
        border-color: $neutrals3; }
    .button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 32px;
            @include m {
                margin: 0 0 32px; } } } }

.clear {
    display: inline-flex;
    align-items: center;
    @include button-1;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $blue;
        svg {
            fill: $blue; } } }

.line {
    display: flex;
    align-items: top;
    .text {
        color: $neutrals4; } }

.icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background: $neutrals6;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    .loader {
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: $neutrals8;
        @include dark {
            background: $neutrals1; } } }

.details {
    flex-grow: 1; }

.subtitle {
    @include body-bold-2; }

.text {
    margin-top: 20px;

    svg {
        fill: $green;
        transition: fill .2s; } }
