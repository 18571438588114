@import "../../styles/helpers";

.actions {
    &.active {
        .button {
            background: $neutrals2;
            box-shadow: inset 0 0 0 2px $neutrals2;
            @include dark {
                background: $neutrals1;
                box-shadow: inset 0 0 0 2px $neutrals1; }
            svg {
                fill: $neutrals8; } }
        .body {
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }

.body {
    margin-top: 30px;
    position: absolute;
    @include m {
        position: static; }
    right: 0;
    width: 342px;
    padding: 6px 16px 10px;
    border-radius: 12px;
    background: $neutrals8;
    box-shadow: 0px 16px 64px -16px rgba(31, 47, 70, 0.3);
    visibility: hidden;
    opacity: 0;
    transform: translateY(3px);
    transition: all .2s;
    @include dark {
        background: $neutrals2;
        box-shadow: inset 0 0 0 2px $neutrals2, 0 4px 24px rgba($neutrals1, .5); } }

.header {
    background-color: $neutrals3; }

.item {
    display: flex;
    align-items: center;
    padding: 16px 0 12px;
    @include button-2;
    cursor: pointer;
    color: $neutrals4;
    transition: color .2s;
    svg {
        margin-right: 8px;
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        color: $pink;
        svg {
            fill: $pink; } }
    &:not(:last-child) {
        border-bottom: 1px solid $neutrals6;
        @include dark {
            border-color: $neutrals3; } } }

.icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    background: $neutrals6;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    transition: all .2s;
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    .loader {
        position: absolute;
        top: -3px;
        left: -3px;
        right: -3px;
        bottom: -3px;
        background: $neutrals8;
        @include dark {
            background: $neutrals1; } } }
