[class^="status"] {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: $neutrals8; }

.status-grey {
  background: transparent;
  color: #000; }

.status-gray {
  background: $gray; }

.status-green {
  background: $green; }

.status-blue-light {
  background: $blue-light; }

.status-pink {
  background: $pink; }

.status-blue {
  background: $blue; }

.status-yellow {
  background: $yellow; }

.status-purple {
  background: $purple; }

.status-black {
  background: $neutrals2; }

.status-stroke-black {
  box-shadow: inset 0 0 0 2px $neutrals6;
  color: $neutrals4;
  @include dark-common {
    box-shadow: inset 0 0 0 2px $neutrals3;
    color: $neutrals8; } }

.status-stroke-green {
  box-shadow: inset 0 0 0 2px $green;
  color: $green; }

[class^="category"] {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: $neutrals8; }

.category-generic {
  background: #868b90; }

.category-vc {
  background: #4A5767; }

.category-vc-trusted {
  background: $pink; }

.category-vc-id {
  background: #FC5C57; }

.category-vc-health {
  background: #00ADA9; }

.category-vc-academy {
  background: #006BE5; }

.category-token {
  background: $green; }

.category-erc20 {
  background: #7B9E00; }

.category-erc721 {
  background: #F6B200; }

.category-tokenized {
  background: #FF7300; }
