@import "../../styles/helpers";

.section {
    padding: 96px 0;
    @include x {
        padding: 80px 0; }
    @include m {
        padding: 64px 0; } }

.container {
    display: flex;
    min-height: 740px;
    align-items: flex-start;
    @include t {
        display: block; } }

.bg {
    position: relative;
    flex-grow: 1;
    margin-right: 96px;
    @include x {
        margin-right: 64px; }
    @include d {
        margin-right: 32px; }
    @include t {
        margin: 0 0 10px; } }

.details {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 584px;
    @include d {
        width: 336px; }
    @include t {
        width: 100%; } }

.avatar {
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    float: left;
    margin-right: 10px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    &:not(:first-child) {
        margin-left: -8px; } }

.preview {
    position: relative;
    img {
        width: 100%;
        border-radius: 16px; } }

.categories {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 10px 0 -8px; }

.category {
    margin: 8px 0 0 8px;
    font-size: 10px; }

.options {
    transform: translateY(0%); }

.title2 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px; }

.cost {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }

.price {
    @include dm-sans;
    font-size: 16px;
    line-height: 30px;
    &:not(:last-child) {
        margin-right: 8px; } }

.counter {
    @include button-1;
    color: $neutrals4; }

.info {
    margin-bottom: 20px;
    @include body-2;
    color: $neutrals4;
    a {
        text-decoration: underline;
        font-weight: 500;
        color: $neutrals2;
        @include dark {
            color: $neutrals8; }
        &:hover {
            text-decoration: none; } } }

.nav {
    display: flex;
    margin-bottom: 32px;
    padding: 6px;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2px $neutrals6;
    @include dark {
        box-shadow: inset 0 0 0 2px $neutrals3; } }

.link {
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    @include button-2;
    color: $neutrals4;
    transition: all .2s;
    &:hover {
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &.active {
        background: $neutrals3;
        color: $neutrals8;
        @include dark {
            background: $neutrals8;
            color: $neutrals2; } }
    &:not(:last-child) {
        margin-right: 8px; } }

.users {
    font-size: 13px;
    margin-bottom: auto; }

.card_wrapper {
    flex: 0 0 calc(33.333% - 32px);
    max-width: calc(33.333% - 32px);
    margin: 0 55px 0 0;
    padding: 0;
    border-radius: 20px;
    @include r(1279) {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include m {
        max-width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 32px; } }
    @include dark {
        background: $neutrals2; } }

.card {
    margin: auto;
    width: 100%;
    overflow: hidden;
    // border: 1px solid #290a0a
    padding: 2%;
    // margin-left: 20px;
    justify-content: center;
    align-items: flex-start;
    box-shadow: 0px 32px 32px rgba(31, 47, 70, 0.12);
    border-radius: 15px;
    @include r(1279) {
        flex: 0 0 calc(50% - 32px);
        max-width: calc(50% - 32px); }
    @include m {
        max-width: 100%;
        margin: 0;
        &:not(:last-child) {
            margin-bottom: 0; } }
    @include dark {
        background: $neutrals2; } }

.preview {
    position: relative;
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    & > img {
        width: 100%; } }

.control {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    transition: all .2s;
    color: #FFFFFF;
    font-family: 'Helvetica Neue', serif;
    @include dark {
        background: $neutrals3d; } }

.topLeft {
    margin: 28px 0 0 15px;
    font-size: 11px;
    line-height: (24/16);
    font-weight: 400; }

.topRight {
    margin: -17px 15px 0 0;
    font-size: 11px;
    line-height: (24/16);
    text-align: right;
    font-weight: 400; }

.title {
    margin: 68px 0 0 80px;
    font-size: 15px;
    line-height: (24/16);
    font-weight: 500; }

.image {
    position: absolute;
    width: 40px;
    left: 25px;
    bottom: 41px; }

.image svg {
    position: absolute;
    left: -6px;
    bottom: 5px;
    width: 51px!important;
    text-align: center;
    align-items: center;
    opacity: 0.8; }

.claim {
    margin: 0 0 0 80px;
    font-size: 12px;
    line-height: (24/16);
    font-weight: 300; }

.bottom {
    margin: 0 0 0 80px;
    font-size: 12px;
    line-height: (24/16);
    font-weight: 200; }

.amount {
    font-size: 16px;
    margin: 3px 0 15px;
    font-weight: 600; }


.token {
    font-size: 13px;
    margin: 3px 0;
    .label {
        font-weight: 500; }
    .text {
        font-weight: 200; } }
