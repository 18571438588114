@import "../../../../styles/helpers";

.title {
    margin-bottom: 15px;
    padding-top: 10px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    font-family: 'Helvetica Neue', serif; }

.info {
    margin: 0 auto 15px;
    text-align: center;
    font-size: 16px;
    font-family: 'Helvetica Neue', serif;
    font-weight: 300;
    span {
        text-decoration: underline; } }

.table {
    margin-bottom: 10px;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid $neutrals6;
    @include dark {
        border-color: $neutrals3; } }

.row {
    display: flex;
    &:not(:last-child) {
        margin-bottom: 10px; } }

.col {
    font-size: 13px;
    &:first-child {
        color: $neutrals4;
        margin-right: auto;
        font-weight: 500;
        padding-right: 16px; }
    &:nth-child(2) {
        flex-shrink: 0;
        width: 230px; } }

.stage {
    margin-bottom: 16px;
    text-align: center;
    @include body-bold-2; }

.socials {
    display: flex;
    justify-content: center; }

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 48px;
    height: 48px;
    border: 2px solid $neutrals6;
    border-radius: 50%;
    transition: border-color .2s;
    @include dark {
        border-color: $neutrals3; }
    svg {
        fill: $neutrals4;
        transition: fill .2s; }
    &:hover {
        border-color: $blue;
        svg {
            fill: $blue; } }
    &:not(:last-child) {
        margin-right: 16px; } }

.btns {
    margin-top: 32px; }

.button {
    font-size: 14px;
    width: 100%;
    &:not(:last-child) {
        margin-bottom: 8px; } }

.success_checkmark {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    transform: scale(0.6);

    .check_icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;

        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px; }

        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
            animation: rotate_circle 4.25s ease-in; }

        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg); }

        .icon_line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;

            &.line_tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
                animation: icon_line_tip 0.75s; }

            &.line_long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
                animation: icon_line_long 0.75s; } }

        .icon_circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5); }

        .icon_fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF; } } }

@keyframes rotate_circle {
    0% {
        transform: rotate(-45deg); }

    5% {
        transform: rotate(-45deg); }

    12% {
        transform: rotate(-405deg); }

    100% {
        transform: rotate(-405deg); } }



@keyframes icon_line_tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px; }

    54% {
        width: 0;
        left: 1px;
        top: 19px; }

    70% {
        width: 50px;
        left: -8px;
        top: 37px; }

    84% {
        width: 17px;
        left: 21px;
        top: 48px; }

    100% {
        width: 25px;
        left: 14px;
        top: 45px; } }


@keyframes icon_line_long {
    0% {
        width: 0;
        right: 46px;
        top: 54px; }

    65% {
        width: 0;
        right: 46px;
        top: 54px; }

    84% {
        width: 55px;
        right: 0px;
        top: 35px; }

    100% {
        width: 47px;
        right: 8px;
        top: 38px; } }
